<template>
  <v-container fluid style="margin-bottom: 100px">
  <!-- <v-container fluid class="d-flex flex-column" style="min-height: calc(100vh - 100px);"> -->
    <v-row align="center" justify="center" >
      <v-col cols="12">
                <v-card class="p-2" outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col class="col-12" sm="6" md="2" lg="2">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="periode"
                                    :items="periodes"
                                    item-value="periode_id"
                                    item-text="periode"
                                    label="periode"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>

                            <v-col class="col-12" sm="6" md="2" lg="2">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="region"
                                    :items="regions"
                                    item-value="region_id"
                                    item-text="descr"
                                    label="Region"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    @change="(event) => getOffice(event)"
                                ></v-autocomplete>
                            </v-col>

                            <v-col class="col-12" sm="6" md="2" lg="2">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="office"
                                    :items="offices"
                                    item-value="office_id"
                                    item-text="office"
                                    label="Office"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            
                            <!-- tanggal pick dari -->
                            <v-col cols="12" xs="12" sm="2" md="2">
                              <v-menu
                                ref="modal"
                                v-model="modal"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    solo
                                    v-model="date_from"
                                    :label="$t('labels.dateFrom')"
                                    persistent-hint
                                    prepend-icon="mdi-calendar"
                                    v-bind="attrs"
                                    v-on="on"
                                    class="ma-0 pa-0 border-12"
                                    hide-details="true"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-if="periode==='Date' || periode==='' || !periode "
                                  v-model="date_from"
                                  no-title
                                  @input="modal = false"
                                ></v-date-picker>
                                <v-date-picker
                                  v-if="periode=='Month'"
                                  v-model="date_from"
                                  type="month"
                                  @input="modal = false"
                                ></v-date-picker>
                              </v-menu>
                            </v-col>


                            <!-- tanggal pick sampai -->
                            <v-col cols="12" xs="12" sm="2" md="2">
                              <v-menu
                                ref="modal_to"
                                v-model="modal_to"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    solo
                                    v-model="date_to"
                                    :label="$t('labels.dateTo')"
                                    persistent-hint
                                    prepend-icon="mdi-calendar"
                                    v-bind="attrs"
                                    v-on="on"
                                    class="ma-0 pa-0 border-12"
                                    hide-details="true"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-if="periode==='Date' || periode==='' || !periode"
                                  v-model="date_to"
                                  no-title
                                  @input="modal_to = false"
                                ></v-date-picker>
                                <v-date-picker
                                  v-if="periode=='Month'"
                                  v-model="date_to"
                                  type="month"
                                  @input="modal_to = false"
                                ></v-date-picker>
                              </v-menu>
                            </v-col>
                            
                            <v-col class="col-12" sm="6" md="2" lg="2">
                                <v-btn class="border-12" style="padding: 20px;"
                                color="info" elevation="2" small @click="searchSalesSearch()"><v-icon>mdi-magnify</v-icon> Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
    </v-row>

    <v-row class="mt-2">
      <v-col class="col-12" sm="6" md="3">
        <v-card outlined height="370">
            <v-card-text>
                <v-img
                class="text-center mx-auto mt-12"
                lazy-src="@/assets/empty_data.png"
                max-width="200"
                src="@/assets/empty_data.png"
                v-if="showDataKategori"
                ></v-img>
                <div id="container1" style="height: 300px; width: 100%;"></div>
            </v-card-text>
        </v-card>
      </v-col>
      <v-col class="col-12" sm="6" md="3">
        <v-card outlined height="370">
            <v-card-text>
                <v-img
                class="text-center mx-auto mt-12"
                lazy-src="@/assets/empty_data.png"
                max-width="200"
                src="@/assets/empty_data.png"
                v-if="showDataKomoditiEksternal"
                ></v-img>
                <div id="container2" style="height: 300px; width: 100%;"></div>
            </v-card-text>
        </v-card>
      </v-col>
      <v-col class="col-12" sm="6" md="3">
        <v-card outlined height="370">
            <v-card-text>
                <v-img
                class="text-center mx-auto mt-12"
                lazy-src="@/assets/empty_data.png"
                max-width="200"
                src="@/assets/empty_data.png"
                v-if="showDataKomoditiInternal"
                ></v-img>
                <div id="container3" style="height: 300px; width: 100%;"></div>
            </v-card-text>
        </v-card>
      </v-col>
      <v-col class="col-12" sm="6" md="3">
        <v-card outlined height="370">
            <v-card-text>
                <v-img
                class="text-center mx-auto mt-12"
                lazy-src="@/assets/empty_data.png"
                max-width="200"
                src="@/assets/empty_data.png"
                v-if="showDataKomoditiJasa"
                ></v-img>
                <div id="container4" style="height: 300px; width: 100%;"></div>
            </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- diagram bawah -->
    <v-row class="mt-2">
      <v-col
        cols="12"
        xs="12" 
        sm="12" 
        md="12"
      >
        <v-card class="pa-md-5">   
          <div id="payment" style="height: 370px; width: 100%;"></div>
          <p></p>
        </v-card>
        
        <!-- dialog pendaftaran -->
        <v-dialog v-model="dialog_inv_payment" max-width="1000px">                
          <v-card class="bg_card">
              <v-progress-linear
                      :active="loading_inv_payment"
                      :indeterminate="loading_inv_payment"
                      absolute
                      bottom
                      color="deep-purple accent-4"
              ></v-progress-linear>
              <v-card-title>Sales Detail</v-card-title>
              <v-card-text>
                    <v-container fluid>
                      <v-row>
                          <v-col cols="12">
                              <!-- <v-card-title>Item Pendaftaran
                                  <v-spacer></v-spacer>
                                  <v-text-field
                                      v-model="$store.state.text"
                                      append-icon="mdi-magnify"
                                      label="Search..."
                                      single-line
                                      @keyup.enter="search_inv_payment"
                                      hide-details
                                  ></v-text-field>
                              </v-card-title> -->
                              <v-data-table
                                  dense
                                  :headers="header_item_inv_payments"
                                  :items="data_pendaftarans"
                                  class="elevation-1 mt-2"
                                  :items-per-page="10"
                                  fixed-header
                                  :divider="true"
                                  :light="true"
                              >
                              <template v-slot:[`item.TglPesan`]="{ item }">
                                {{ (new Date(new Date(item.TglPesan) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) }}
                              </template>
                              <template v-slot:[`item.Lembar`]="{ item }">
                                  {{ $store.getters.convertToCurrencyNoFrac(item.Lembar) }}
                              </template>
                              <template v-slot:[`item.HargaSatuan`]="{ item }">
                                  {{ $store.getters.convertToCurrencyNoFrac(item.HargaSatuan) }}
                              </template>
                              <template v-slot:[`item.Total`]="{ item }">
                                  {{ $store.getters.convertToCurrencyNoFrac(item.Total) }}
                              </template>
                              </v-data-table>
                          </v-col>
                      </v-row>
                  </v-container>
              </v-card-text>
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close_inv_payment">Close</v-btn>
              </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row class="mt-2">
    <!-- <v-row class="flex-grow-1"> -->
      <v-col class="col-12" sm="6" md="12">
        <v-data-table
            dense
            :headers="header_item_inv_payments"
            :items="data_pendaftarans"
            class="elevation-1 mt-2"
            :items-per-page="10"
            fixed-header
            :divider="true"
            :light="true"
        >
        <template v-slot:[`item.TglPesan`]="{ item }">
          {{ (new Date(new Date(item.TglPesan) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) }}
        </template>
        <template v-slot:[`item.Lembar`]="{ item }">
            {{ $store.getters.convertToCurrencyNoFrac(item.Lembar) }}
        </template>
        <template v-slot:[`item.HargaSatuan`]="{ item }">
            {{ $store.getters.convertToCurrencyNoFrac(item.HargaSatuan) }}
        </template>
        <template v-slot:[`item.Total`]="{ item }">
            {{ $store.getters.convertToCurrencyNoFrac(item.Total) }}
        </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row class="mt-2" style="flex-grow: 1; height: 5%;">
      <v-col class="col-12" sm="6" md="12">
        <div style="height: 100%; width: 100%;"></div>
      </v-col>
    </v-row>



  </v-container>
</template>
<script>

import {kriMaster} from "@/backend-api/kri/kriMaster"
import {backendApi} from "@/backend-api/backend-api-sr"


export default {
  data() {
      return {
        periodes:[
          'Month',
          'Date'
        ],
        office:'',
        offices: [],
        periode:'',
        display: 'none',
        period_other_payment: '',
        dialog_other_payment: false,

        loading_other_payment: false,
        total_item_other_payment: 10,
        
        period_inv: '',
        dialog_inv_payment: false,
        pagination_inv_payment: 1,
        period: '',
        dialog_payment: false,
        pagination_payment: 1,
        loading_payment: false,
        
        option_item_payments:{},
        loading_inv_payment: false,
        
        total_item_inv_payments: 10,
        pagination_inv_payment: 1,
        header_item_inv_payments:[
          {
              text: 'Order Number',
              align: 'start',
              sortable: true,
              value: 'CustomerOrderNo',
          },
          { text: 'Tanggal', value: 'TglPesan' },
          { text: 'Office', value: 'office' },
          { text: 'Barang', value: 'NamaBarang' },
          { text: 'Qty', value: 'Lembar' },
          { text: 'Harga', value: 'HargaSatuan' },
          { text: 'Total', value: 'Total' },
        ],
        data_pendaftarans: [],
        option_item_inv_payments:{},
        shop_summary_lastest: [],

        dialog_detail_paket: false,
        pagination_shop: 1, 
        last_month: '',
        loading_shop_last_month: false,
        detail_shop_summary_lastest: [],
        total_item_shops: 10,
        option_item_shops:{},
        date_from:'',
        modal: false,
        date_to: "",
        modal_to: false,
        showDataKategori: true,
        showDataKomoditi: true,
        showDataKomoditiEksternal: true,
        showDataKomoditiInternal: true,
        showDataKomoditiJasa: true,
        dt_per_kategori: [],
        dt_per_komoditi: [],

      }
  },
  async mounted(){
    this.getRegion()
    this.getOffice()
    this.searchSalesYear()
  },
  methods:{

    async searchSalesYear(){
      //pie_chart
      await axios.get(`${process.env.VUE_APP_URL}/api/kri/sales/salesAnalysis/showSalesYearKategori?region_id=${this.region ? this.region : ""}&office_id=${this.office ? this.office : ""}&date_from=${this.date_from ? this.date_from : ""}&date_to=${this.date_to ? this.date_to : ""}`, { 
        headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
      })
      .then(res => {
        this.data_per_kategori = res.data
        this.showDataKategori= false
        this.renderChartKategori(this.data_per_kategori);
      });

      await axios.get(`${process.env.VUE_APP_URL}/api/kri/sales/salesAnalysis/showSalesYearKomoditi?region_id=${this.region ? this.region : ""}&office_id=${this.office ? this.office : ""}&date_from=${this.date_from ? this.date_from : ""}&date_to=${this.date_to ? this.date_to : ""}&cat=1`, { 
        headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
      })
      .then(res => {
        this.data_per_komodity = res.data
        this.showDataKomoditiEksternal= false
        this.renderChartKomoditiEksternal(this.data_per_komodity);
      });

      await axios.get(`${process.env.VUE_APP_URL}/api/kri/sales/salesAnalysis/showSalesYearKomoditi?region_id=${this.region ? this.region : ""}&office_id=${this.office ? this.office : ""}&date_from=${this.date_from ? this.date_from : ""}&date_to=${this.date_to ? this.date_to : ""}&cat=2`, { 
        headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
      })
      .then(res => {
        this.data_per_komodity = res.data
        this.showDataKomoditiInternal= false
        this.renderChartKomoditiInternal(this.data_per_komodity);
      });

      await axios.get(`${process.env.VUE_APP_URL}/api/kri/sales/salesAnalysis/showSalesYearKomoditi?region_id=${this.region ? this.region : ""}&office_id=${this.office ? this.office : ""}&date_from=${this.date_from ? this.date_from : ""}&date_to=${this.date_to ? this.date_to : ""}&cat=4`, { 
        headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
      })
      .then(res => {
        this.data_per_komodity = res.data
        this.showDataKomoditiJasa= false
        this.renderChartKomoditiJasa(this.data_per_komodity);
      });
      
      await axios.get(`${process.env.VUE_APP_URL}/api/kri/sales/salesAnalysis/showSalesYear?region_id=${this.region ? this.region : ""}&office_id=${this.office ? this.office : ""}&date_from=${this.date_from ? this.date_from : ""}&date_to=${this.date_to ? this.date_to : ""}`, { 
        headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
      })
      .then(res => {
        this.data_payments = res.data
        // this.renderChartSalesYear(this.data_payments)
      });
      await axios.get(`${process.env.VUE_APP_URL}/api/kri/sales/salesAnalysis/showReturYear?region_id=${this.region ? this.region : ""}&office_id=${this.office ? this.office : ""}&date_from=${this.date_from ? this.date_from : ""}&date_to=${this.date_to ? this.date_to : ""}`, { 
        headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
      })
      .then(res => {
        this.data_returs = res.data
        // this.renderChartSalesYear(this.data_returs)
      });
      this.renderChartSalesYear(this.data_payments,this.data_returs)

      
    },

    async searchSalesSearch(){
      // var start_date = this.date_from
      //   ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g, "")
      //   : "";
      // var end_date = this.date_to
      //   ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g, "")
      //   : "";

      var period = "";
      if (this.periode == "Month") {
        period = "1";
        //pie_chart
        await axios.get(`${process.env.VUE_APP_URL}/api/kri/sales/salesAnalysis/showSalesMonthKategori?region_id=${this.region ? this.region : ""}&office_id=${this.office ? this.office : ""}&date_from=${this.date_from ? this.date_from : ""}&date_to=${this.date_to ? this.date_to : ""}`, { 
          headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
          this.data_per_kategori = res.data
          this.showDataKategori= false
          this.renderChartKategori(this.data_per_kategori);
        });

        await axios.get(`${process.env.VUE_APP_URL}/api/kri/sales/salesAnalysis/showSalesMonthKomoditi?region_id=${this.region ? this.region : ""}&office_id=${this.office ? this.office : ""}&date_from=${this.date_from ? this.date_from : ""}&date_to=${this.date_to ? this.date_to : ""}&cat=1`, { 
          headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
          this.data_per_komodity = res.data
          this.showDataKomoditiEksternal= false
          this.renderChartKomoditiEksternal(this.data_per_komodity);
        });

        await axios.get(`${process.env.VUE_APP_URL}/api/kri/sales/salesAnalysis/showSalesMonthKomoditi?region_id=${this.region ? this.region : ""}&office_id=${this.office ? this.office : ""}&date_from=${this.date_from ? this.date_from : ""}&date_to=${this.date_to ? this.date_to : ""}&cat=2`, { 
          headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
          this.data_per_komodity = res.data
          this.showDataKomoditiInternal= false
          this.renderChartKomoditiInternal(this.data_per_komodity);
        });

        await axios.get(`${process.env.VUE_APP_URL}/api/kri/sales/salesAnalysis/showSalesMonthKomoditi?region_id=${this.region ? this.region : ""}&office_id=${this.office ? this.office : ""}&date_from=${this.date_from ? this.date_from : ""}&date_to=${this.date_to ? this.date_to : ""}&cat=4`, { 
          headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
          this.data_per_komodity = res.data
          this.showDataKomoditiJasa= false
          this.renderChartKomoditiJasa(this.data_per_komodity);
        });

        await axios.get(`${process.env.VUE_APP_URL}/api/kri/sales/salesAnalysis/showSalesSearchMonth?region_id=${this.region ? this.region : ""}&office_id=${this.office ? this.office : ""}&date_from=${this.date_from ? this.date_from : ""}&date_to=${this.date_to ? this.date_to : ""}`, { 
          headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
          this.data_payments = res.data
          // this.renderChartSalesYear(this.data_payments)
        });
        await axios.get(`${process.env.VUE_APP_URL}/api/kri/sales/salesAnalysis/showReturMonth?region_id=${this.region ? this.region : ""}&office_id=${this.office ? this.office : ""}&date_from=${this.date_from ? this.date_from : ""}&date_to=${this.date_to ? this.date_to : ""}`, { 
          headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
          this.data_returs = res.data
          // this.renderChartSalesYear(this.data_returs)
        });
        this.renderChartSalesYear(this.data_payments,this.data_returs)

        

      } else if (this.periode == "Date") {
        period = "2";
        //pie_chart
        await axios.get(`${process.env.VUE_APP_URL}/api/kri/sales/salesAnalysis/showSalesYearKategori?region_id=${this.region ? this.region : ""}&office_id=${this.office ? this.office : ""}&date_from=${this.date_from ? this.date_from : ""}&date_to=${this.date_to ? this.date_to : ""}`, { 
          headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
          this.data_per_kategori = res.data
          this.showDataKategori= false
          this.renderChartKategori(this.data_per_kategori);
        });

        await axios.get(`${process.env.VUE_APP_URL}/api/kri/sales/salesAnalysis/showSalesYearKomoditi?region_id=${this.region ? this.region : ""}&office_id=${this.office ? this.office : ""}&date_from=${this.date_from ? this.date_from : ""}&date_to=${this.date_to ? this.date_to : ""}&cat=1`, { 
          headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
          this.data_per_komodity = res.data
          this.showDataKomoditiEksternal= false
          this.renderChartKomoditiEksternal(this.data_per_komodity);
        });

        await axios.get(`${process.env.VUE_APP_URL}/api/kri/sales/salesAnalysis/showSalesYearKomoditi?region_id=${this.region ? this.region : ""}&office_id=${this.office ? this.office : ""}&date_from=${this.date_from ? this.date_from : ""}&date_to=${this.date_to ? this.date_to : ""}&cat=2`, { 
          headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
          this.data_per_komodity = res.data
          this.showDataKomoditiInternal= false
          this.renderChartKomoditiInternal(this.data_per_komodity);
        });

        await axios.get(`${process.env.VUE_APP_URL}/api/kri/sales/salesAnalysis/showSalesYearKomoditi?region_id=${this.region ? this.region : ""}&office_id=${this.office ? this.office : ""}&date_from=${this.date_from ? this.date_from : ""}&date_to=${this.date_to ? this.date_to : ""}&cat=4`, { 
          headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
          this.data_per_komodity = res.data
          this.showDataKomoditiJasa= false
          this.renderChartKomoditiJasa(this.data_per_komodity);
        });

        await axios.get(`${process.env.VUE_APP_URL}/api/kri/sales/salesAnalysis/showSalesSearchDay?region_id=${this.region ? this.region : ""}&office_id=${this.office ? this.office : ""}&date_from=${this.date_from ? this.date_from : ""}&date_to=${this.date_to ? this.date_to : ""}`, { 
          headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
          this.data_payments = res.data
          // this.renderChartSalesDay(this.data_payments)
        });
        await axios.get(`${process.env.VUE_APP_URL}/api/kri/sales/salesAnalysis/showReturDay?region_id=${this.region ? this.region : ""}&office_id=${this.office ? this.office : ""}&date_from=${this.date_from ? this.date_from : ""}&date_to=${this.date_to ? this.date_to : ""}`, { 
          headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
          this.data_returs = res.data
          // this.renderChartSalesDay(this.data_payments)
        });
        this.renderChartSalesDay(this.data_payments,this.data_returs)

        
      } else {
        period = "0";
        this.searchSalesYear()
      }

      
    },

    async getOffice(){
      var urlFilter = '?'
      if(this.region != '' && this.region != null){
        urlFilter = `${urlFilter}region_id=${this.region}`
      }


      this.offices=[]
      await axios.post(`${process.env.VUE_APP_URL}/api/kri/master/getOffice`, {
        region_id : this.region ? this.region : ""
      }, { 
          headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
      })
      .then(res => {
          this.offices  = res.data.data
      })
    },
    async getRegion(){
      // var respData = await kriMaster.getRegion(``, null, false, false, false)
      // if (respData.status === 200) {
      //     this.regions = respData.data.data
      // }
      this.regions=[]
      await axios.post(`${process.env.VUE_APP_URL}/api/kri/master/getRegion`, {

      }, { 
          headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
      })
      .then(res => {
          this.regions  = res.data.data
      })
    },
    // getPullData(){

    // },

    //   search_shop(){
    //     this.getItemShop(page, itemsPerPage, this.last_month)
    //   },
    //   search_other_payment(){
    //     this.getItemOtherPayment(page, itemsPerPage, this.period_other_payment)
    //   },
    //   search_payment(){
    //     this.getItemPayment(page, itemsPerPage, this.period)
    //   },
      // search_inv_payment(){
      //   this.getItemInvPayment(page, itemsPerPage, this.period_inv)
      // },
      // close_payment(){
      //   this.dialog_payment = false
      // },
      // close_other_payment(){
      //   this.dialog_other_payment = false
      // },
      close_inv_payment(){
        this.dialog_inv_payment = false
      },
      // close_shop(){
      //   this.dialog_detail_paket = false
      //   this.dialog_sebaran_umur  = false
      // },
      
      renderChartSalesYear(datas,datas2){
        var chart3 = new CanvasJS.Chart("payment", {
                      animationEnabled: true,
                      theme: "light2",
                      title: {
                          text: "Sales Data",
                          fontFamily: "Calibri",
                          fontSize: 20
                      },
                      axisX:{
                        title : "Year-Month"
                      },
                      axisY: {
                        title: "Million",
                        titleFontColor: "#4F81BC",
                        lineColor: "#4F81BC",
                        labelFontColor: "#4F81BC",
                        tickColor: "#4F81BC",
                        includeZero: false
                      },
                      data: [
                      {
                        type: "column",
                        name: "Sales",
                        click: this.onClickDetailSalesYear,
                        showInLegend: true,
                        indexLabelPlacement: "inside",  
                        indexLabelOrientation: "vertical",
                        color: "#f79b07",
                        yValueFormatString: "###,###.00\"\"",
                        indexLabel: "{y}",
                        dataPoints: datas
                      },
                      {
                        type: "column",
                        name: "Retur",
                        click: this.onClickDetailReturYear,
                        showInLegend: true,
                        indexLabelPlacement: "inside",  
                        indexLabelOrientation: "vertical",
                        color: "#f80b67",
                        yValueFormatString: "###,###.00\"\"",
                        indexLabel: "{y}",
                        dataPoints: datas2
                      },
                      ]
                    });
        chart3.render();
      },

      renderChartSalesDay(datas,datas2){
         // Filter out dates with no data for datas
        datas = datas.filter(data => data.y !== null && data.y !== undefined);

        // Convert 'label' to 'x' with Date objects for datas
        datas.forEach(data => {
            data.x = new Date(data.label);
            data.originalLabel = data.label;
            delete data.label; // Remove the 'label' property to avoid conflicts
        });

        // Filter out dates with no data for datas2
        datas2 = datas2.filter(data => data.y !== null && data.y !== undefined);

        // Convert 'label' to 'x' with Date objects for datas2
        datas2.forEach(data => {
            data.x = new Date(data.label);
            data.originalLabel = data.label;
            delete data.label; // Remove the 'label' property to avoid conflicts
        });

        var chart3 = new CanvasJS.Chart("payment", {
                      animationEnabled: true,
                      theme: "light2",
                      title: {
                          text: "Sales Data",
                          fontFamily: "Calibri",
                          fontSize: 20
                      },
                      // axisX:{
                      //   title : "Date",
                      //   valueFormatString: "DD-MM-YY"
                      // },
                      axisX: {
                          title: "Date",
                          valueFormatString: "DD-MM",
                          interval: 1, // Show all labels by setting interval to 1
                          intervalType: "day", // Ensure the interval is interpreted as days
                          labelAngle: -45 // Rotate labels to avoid overlapping
                      },
                      axisY: {
                        title: "Million",
                        titleFontColor: "#4F81BC",
                        lineColor: "#4F81BC",
                        labelFontColor: "#4F81BC",
                        tickColor: "#4F81BC",
                        includeZero: false
                      },
                      data: [
                      {
                        type: "column",
                        name: "Sales",
                        click: this.onClickDetailSalesDay,
                        showInLegend: true,
                        indexLabelPlacement: "inside",  
                        indexLabelOrientation: "horizontal",
                        color: "#6FC0D8",
                        yValueFormatString: "###,###.00\"\"",
                        indexLabel: "{y}",
                        dataPoints: datas
                      },
                      {
                        type: "column",
                        name: "Retur",
                        click: this.onClickDetailReturDay,
                        showInLegend: true,
                        indexLabelPlacement: "inside",  
                        indexLabelOrientation: "horizontal",
                        color: "#f80b67",
                        yValueFormatString: "###,###.00\"\"",
                        indexLabel: "{y}",
                        dataPoints: datas2
                      },
                      ]
                    });
        chart3.render();
      },

      onClickDetailSalesYear(e){
        this.data_pendaftarans=[]
        this.period_inv = e.dataPoint.label
        // this.dialog_inv_payment = true
        this.pagination_inv_payment = 1

        this.getItemDetailSalesYear(1,10,e.dataPoint.label)
      },
      async getItemDetailSalesYear(page = 1, itemsPerPage = 10, item){
        await axios.post(`${process.env.VUE_APP_URL}/api/kri/sales/salesAnalysis/showSalesYearDetail`, {
                period : this.period_inv ? this.period_inv : "",
                office_id : this.office ? this.office : ""
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.data_pendaftarans  = res.data.data
                // this.$store.dispatch('setOverlay', false)
            })
            console.log('Detail data:', data_pendaftarans);
      },

      onClickDetailReturYear(e){
        this.data_pendaftarans=[]
        this.period_inv = e.dataPoint.label
        // this.dialog_inv_payment = true
        this.pagination_inv_payment = 1

        this.getItemDetailReturYear(1,10,e.dataPoint.label)
      },
      async getItemDetailReturYear(page = 1, itemsPerPage = 10, item){
        await axios.post(`${process.env.VUE_APP_URL}/api/kri/sales/salesAnalysis/showReturYearDetail`, {
                period : this.period_inv ? this.period_inv : "",
                office_id : this.office ? this.office : ""
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.data_pendaftarans  = res.data.data
                // this.$store.dispatch('setOverlay', false)
            })
            // console.log('Detail data:', data_pendaftarans);
      },

      onClickDetailSalesDay(e){
        this.data_pendaftarans=[]
        this.period_inv = e.dataPoint.originalLabel
        // this.dialog_inv_payment = true
        this.pagination_inv_payment = 1

        this.getItemDetailSalesDay(1,10,e.dataPoint.originalLabel)
      },
      async getItemDetailSalesDay(page = 1, itemsPerPage = 10, item){
        // this.loading_inv_payment = true
        // await axios.get(`${process.env.VUE_APP_URL}/api/kri/sales/salesAnalysis/showSalesDayDetail?period=${this.period_inv ? this.period_inv : ""}&page=${page > 1 ? page : 1}&pagination=${itemsPerPage}&office_id=${this.office ? this.office : ""}`, { 
        //     headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        // })
        // .then(res => {
        //     this.data_pendaftarans = res.data.data
        // })
        console.log('item = '+item);
        await axios.post(`${process.env.VUE_APP_URL}/api/kri/sales/salesAnalysis/showSalesDayDetail`, {
                period : item ? item : "",
                office_id : this.office ? this.office : "",
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.data_pendaftarans  = res.data.data
                // this.$store.dispatch('setOverlay', false)
            })
            console.log('Detail data:', data_pendaftarans);
      },
      onClickDetailReturDay(e){
        this.data_pendaftarans=[]
        this.period_inv = e.dataPoint.originalLabel
        // this.dialog_inv_payment = true
        this.pagination_inv_payment = 1

        this.getItemDetailReturDay(1,10,e.dataPoint.originalLabel)
      },
      async getItemDetailReturDay(page = 1, itemsPerPage = 10, item){
        // this.loading_inv_payment = true
        // await axios.get(`${process.env.VUE_APP_URL}/api/kri/sales/salesAnalysis/showSalesDayDetail?period=${this.period_inv ? this.period_inv : ""}&page=${page > 1 ? page : 1}&pagination=${itemsPerPage}&office_id=${this.office ? this.office : ""}`, { 
        //     headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        // })
        // .then(res => {
        //     this.data_pendaftarans = res.data.data
        // })
        await axios.post(`${process.env.VUE_APP_URL}/api/kri/sales/salesAnalysis/showReturDayDetail`, {
                period : this.period_inv ? this.period_inv : "",
                office_id : this.office ? this.office : "",
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.data_pendaftarans  = res.data.data
                // this.$store.dispatch('setOverlay', false)
            })
            console.log('Detail data:', data_pendaftarans);
      },

      getPieChart(dt_detail, container_detail, title_detail, label_detail, destroy = false){
            var pie_chart = new CanvasJS.Chart(container_detail, {
                animationEnabled: true,
                exportEnabled: true,
                theme: "light2",
                exportEnabled: true,
                title: {
                    text: title_detail,
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: true,
                subtitles:[
                    {
                        text: label_detail,
                        //Uncomment properties below to see how they behave
                        fontFamily: "Calibri",
                        fontColor: "red",
                        fontSize: 12
                    }
                ],
                legend: {
                    itemclick: this.toggleDataPointVisibility
                },
                data: [{

                    type: "pie",
                    percentFormatString: "#0.##",
                    indexLabel: "{label} #percent%",
                    indexLabelFontSize: 12,

                }]
            });

            pie_chart.options.data[0].dataPoints = dt_detail;
            this.showDefaultText(pie_chart, "No Data Found!");
            var x = window.matchMedia("(max-width: 991px)");

            if (destroy == true) {
                pie_chart.destroy();
            } else {
                if (x.matches) {

                    for(var i = 0; i < pie_chart.options.data.length; i++){
                        pie_chart.options.data[i].indexLabelFontSize = 6;
                    }
                    pie_chart.render();
                }

                pie_chart.render();
            }
        },

        renderChartKategori(datas){
          const chart = new CanvasJS.Chart("container1", {
            theme: "light2",
            exportEnabled: true,
            animationEnabled: true,
            title: {
              text: "Sales by Category"
            },
            data: [{
              type: "pie",
              startAngle: 25,
              toolTipContent: "<b>{label}</b>: {y}%",
              showInLegend: true,
              legendText: "{label}",
              indexLabelFontSize: 16,
              indexLabel: "{label} - {y}%",
              dataPoints: datas // Passing the data to the pie chart
            }]
          });
          chart.render();
        },
        renderChartKomoditiEksternal(datas){
          const chart = new CanvasJS.Chart("container2", {
            theme: "light2",
            exportEnabled: true,
            animationEnabled: true,
            title: {
              text: "Comodity on External Category"
            },
            data: [{
              type: "pie",
              startAngle: 25,
              toolTipContent: "<b>{label}</b>: {y}%",
              showInLegend: true,
              legendText: "{label}",
              indexLabelFontSize: 16,
              indexLabel: "{label} - {y}%",
              dataPoints: datas // Passing the data to the pie chart
            }]
          });
          chart.render();
        },
        renderChartKomoditiInternal(datas){
          const chart = new CanvasJS.Chart("container3", {
            theme: "light2",
            exportEnabled: true,
            animationEnabled: true,
            title: {
              text: "Comodity on Internal Category"
            },
            data: [{
              type: "pie",
              startAngle: 25,
              toolTipContent: "<b>{label}</b>: {y}%",
              showInLegend: true,
              legendText: "{label}",
              indexLabelFontSize: 16,
              indexLabel: "{label} - {y}%",
              dataPoints: datas // Passing the data to the pie chart
            }]
          });
          chart.render();
        },
        renderChartKomoditiJasa(datas){
          const chart = new CanvasJS.Chart("container4", {
            theme: "light2",
            exportEnabled: true,
            animationEnabled: true,
            title: {
              text: "Comodity on Jasa Category"
            },
            data: [{
              type: "pie",
              startAngle: 25,
              toolTipContent: "<b>{label}</b>: {y}%",
              showInLegend: true,
              legendText: "{label}",
              indexLabelFontSize: 16,
              indexLabel: "{label} - {y}%",
              dataPoints: datas // Passing the data to the pie chart
            }]
          });
          chart.render();
        },
      
  },
  watch: {
      option_item_other_payments:{
        handler () {
            // this.loading_other_payment = true
            const { page, itemsPerPage } = this.option_item_other_payments
            if (this.period_other_payment) {
                this.getItemOtherPayment(page, itemsPerPage, this.period_other_payment)
            }
        },
        deep: true,
      },
      option_item_payments:{
        handler () {
              // this.loading_payment = true
              const { page, itemsPerPage } = this.option_item_payments
              if (this.period) {
                  this.getItemPayment(page, itemsPerPage, this.period)
              }
          },
          deep: true,
      },
      // option_item_inv_payments:{
      //   handler () {
      //         // this.loading_inv_payment = true
      //         const { page, itemsPerPage } = this.option_item_inv_payments
      //         if (this.period_inv) {
      //             this.getItemInvPayment(page, itemsPerPage, this.period_inv)
      //         }
      //     },
      //     deep: true,
      // },
      option_item_shops:{
        handler () {
              // this.loading_shop_last_month = true
              const { page, itemsPerPage } = this.option_item_shops
              if (this.last_month) {
                  this.getItemShop(page, itemsPerPage, this.last_month)
              }
          },
          deep: true,
      },
  }
}
</script>